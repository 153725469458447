import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import Layout from "../components/layout";

import RelNoteWrapper from "../components/relnote-wrapper";


const RelNotesPage = () => {
  return (
    <Layout logo={true}>
     
      <BodyStyle>
        <Container>
          <Row className="justify-content-md-center row-padtop">
            <Col md={8}>
              <div className="desc">
                <RelNoteWrapper />
              </div>
            </Col>
          </Row>
        </Container>
      </BodyStyle>
    </Layout>
  );
};

const BodyStyle = styled.div`
  h3 {
    color: #7c0b47;
    font-family: "Roboto", sans-serif;
    font-weight: 800;
    margin-top: 1.4em;
    margin-bottom: 0.4em;
  }

  h1 {
    color: #7c0b47;
    font-family: "Roboto", sans-serif;
  }
  
  li {
    margin-bottom: 0.2em;
  }

  h4 {
    color: #7c0b47;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    margin-bottom: 0.5em;
  }

  .desc {
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 1.1em;
  }

  .head0 {
    color: #7c0b47;
    font-family: "Roboto", sans-serif;
    font-weight: 700;
  }
`;

export default RelNotesPage;
